<template>
  <div style="background: linear-gradient(to top, #FFF, #F8F9FD);height: 97.6%;">
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/alarmRules' }">告警规则</el-breadcrumb-item>
          <el-breadcrumb-item>新建规则</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="form-box">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
          <div class="white-bg-card">
            <div class="form-title">基本信息</div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="规则名称" prop="name">
                  <el-input v-model="form.name" placeholder="请输入规则名称" maxlength="30" show-word-limit></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="告警级别" prop="level">
                  <el-radio-group v-model="form.level" style="width:100%;">
                    <el-radio :label="1">提示告警</el-radio>
                    <el-radio :label="2">普通告警</el-radio>
                    <el-radio :label="3">重要告警</el-radio>
                    <el-radio :label="4">严重告警</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="规则内容" prop="content">
                  <el-input type="textarea" v-model="form.content" placeholder="请输入规则内容" maxlength="80" show-word-limit rows="5"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="通知方式" prop="serious">
                      <div class="notify-method">
                        <el-checkbox-group v-model="form.serious">
                          <el-checkbox label="system">系统内通知</el-checkbox>
                          <el-checkbox label="sms">短信</el-checkbox>
                          <el-checkbox label="entWeChatRobot">企业微信</el-checkbox>
                          <span style="margin-left:30px;" v-if="form.alarmGroup=='equipment'||form.alarmGroup=='equipmentOffline'">
                            <el-checkbox label="voice">语音播报</el-checkbox>
                            <el-checkbox label="popup">组态内弹窗</el-checkbox>
                          </span>
                        </el-checkbox-group>
                      </div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <!-- 选择机器人 -->
                    <div v-if="form.serious.find(item=>item=='entWeChatRobot')=='entWeChatRobot'">
                      <robot @onRobots="onRobot"></robot>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="规则类型" prop="alarmGroup">
                  <el-select v-model="form.alarmGroup" placeholder="请选择规则类型" style="width:500px;">
                    <el-option
                      v-for="item in typeRules"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12" v-if="form.alarmGroup!='equipment'&&form.alarmGroup!='equipmentOffline'">
                <el-form-item label="通知人">
                  <div @click="addStaff()" class="staffBox">
                    <div v-if="staffList.length>0">
                      <el-tag v-for="staff in staffList" :key="staff.id" style="margin-right: 4px;">
                        {{ staff.name }}
                      </el-tag>
                    </div>
                    <div v-else style="color: #909399;text-align: center;">请选择通知人员</div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <!-- 设备类型 -->
          <div class="white-bg-card" v-if="form.alarmGroup=='equipment'">
            <configuration :equipmentss="equipments" :resource="'add'"></configuration>
          </div>
          
          <!-- 设备离线告警 -->
          <div class="white-bg-card" v-if="form.alarmGroup=='equipmentOffline'">
            <offlineConfiguration :equipmentsOfflines="equipmentsOffline" :resource="'add'"></offlineConfiguration>
          </div>

          <div class="bottom-btn">
            <el-button type="primary" size="small" @click="$router.push('/alarmRules')">取消</el-button>
            <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
          </div>
        </el-form>
      </div>
      <el-drawer
        title=""
        :with-header="false"
        :visible.sync="drawer"
        direction="rtl"
        size="1000px">
        <div class="drawer-title">选择通知人员</div>
        <div class="drawer-content" v-if="drawer">
          <div class="table-tree">
            <div class="tree">
              <div class="tree-title"><i class="el-icon-share"></i> 结构</div>
              <el-tree
                :data="treeData"
                @node-click="treeRowClick"
                :props="defaultProps"
                :expand-on-click-node="false"
                default-expand-all
                ref="tree">
                <span class="custom-tree-node" slot-scope="{ node }">
                  <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
                </span>
              </el-tree>
            </div>
            
            <div class="table">
              <el-table :data="tableData" style="width: 100%" height="700">
                <el-table-column width="40">
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.check" :checked="staffListID.includes(scope.row.id)" @change="checked(scope.row)"></el-checkbox>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="sex" label="性别">
                  <template slot-scope="scope">
                    <span v-if="scope.row.sex == 1">男</span>
                    <span v-else>女</span>
                  </template>
                </el-table-column>
                <el-table-column prop="departmentName" label="部门"></el-table-column>
                <el-table-column prop="name" label="岗位">
                  <template slot-scope="scope">
                    <div
                      v-for="item in scope.row.positionInfoList"
                      :key="item.positionId"
                    >{{item.positionName}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="phone" label="电话"></el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="drawer-bottom">
          <el-button size="small" type="primary" @click="drawer=false">取消</el-button>
          <el-button size="small" type="primary" @click="drawerOK" class="drawer-bottom-ok">确 定</el-button>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import layTime from '@/components/layTime'
import configuration from './configuration'
import offlineConfiguration from './offlineConfiguration'
import robot from './robot'
export default {
  components: {
    layTime,
    configuration,
    offlineConfiguration,
    robot
  },
  data() {
    return {
      loading: false,
      form: {
        level: 1,
        serious: ['system'],
      },
      typeRules: [
        {code: 'operationTaskRuleExpiration',name: '巡点检规则过期告警'},
        {code: 'lubeTaskRuleExpiration',name: '润滑规则过期告警'},
        {code: 'equipment',name: '设备类型'},
        {code: 'equipmentOffline',name: '设备离线告警'},
      ],
      rules: {
        name: [{ required: true, message: "必填项", trigger: "blur" }],
        content: [{ required: true, message: "必填项", trigger: "blur" }],
        alarmGroup: [{ required: true, message: "必填项", trigger: "change" }],
        level: [{ required: true, message: "必填项", trigger: "change" }],
        serious: [{ required: true, message: "必填项", trigger: "change" }],
      },
      treeData: [],
      tableData: [],
      defaultProps: {
        children: 'childTree',
        label: 'name'
      },
      drawer: false,
      staffList: [], // 通知人员列表
      staffListID: [],
      checkedStaffList: [],
      equipments: [{
        drawer: false,
        configRadio: '',
        tagDrawer: false,
        tagRadio: '',
        abilityOrPointTable: null,
        parameterEn: null,
        parameterCn: null,
        pointName: null,
        describe: null,

        gatewayEid:null,
        eid:null,
        equipmentBrandCode: null,
        equipmentName: null,
        triggerCondition: {
          dataType:null,
          boolData: {
            formulaType: '',
            tag: '',
            tagValue: '',
            tagValueDescribe:'',
            boolTag: '',
            boolTagEn: '',
            boolUnit: '',
            boolDesc: '',
            boolTag1: '',
            boolTag1En: '',
            tagCustom: [{
              tagDrawer: false,
              tagRadio: '',
              name: ''
            },
            {
              tagDrawer: false,
              tagRadio: '',
              name: ''
            }],
          },
          realData:{
            tag:null,
            formulaType:null,
            threshold:null,
            minThreshold:null,
            maxThreshold:null
          }
        }
      }],
      equipmentsOffline: [{
        drawer: false,
        configRadio: '',
        gatewayEid:null,
        eid:null,
        equipmentBrandCode: null,
        equipmentName: null,
      }]
    };
  },
  methods: {
    onRobot(val) {
      this.form.robotId = val.robotId
      this.form.robotName = val.robotName
    },
    // 选择通知人员
    addStaff() {
      this.loadTableData(this.treeData[0].id)
      this.staffListID = []
      this.staffList.forEach(item => {
        this.staffListID.push(item.id)
      })
      this.checkedStaffList = [].concat(this.staffList)
      this.drawer = true
    },
    // 获取部门结构树
    loadTreeData() {
      this.$ajax.post('departmentResponsibleChild').then(res => {
        this.treeData = res.data
      })
    },
    // 获取部门人员
    loadTableData(id) {
      this.$ajax.post('queryFilter', {
        departmentId: id ? id : 0
      }).then(res => {
        res.data.forEach(item => {
          let inIs = this.staffListID.find(function(obj) {
            return obj == item.id
          })
          if (!inIs) {
            item.check = false
          }else {
            item.check = true
          }
        })
        this.tableData = res.data
      })
    },
    // 选择部门结构
    treeRowClick(data) {
      this.loadTableData(data.id)
    },
    // 勾选巡检员
    checked(row) {
      if (!this.staffListID.includes(row.id)) {
        this.checkedStaffList.push(row)
        this.staffListID.push(row.id)
      } else {
        this.checkedStaffList.forEach((item, index) => {
          if (item.id == row.id) {
            this.checkedStaffList.splice(index, 1)
            this.$delete(this.staffListID, index)
          }
        })
      }
    },
    // 选择通知人员点击确定
    drawerOK() {
      this.drawer = false
      this.staffList = [].concat(this.checkedStaffList)
      this.staffListID = [].concat(this.staffListID)
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let form = {
            title: this.form.name,
            content: this.form.content,
            alarmTypeCode: this.form.alarmGroup,
            receiverIds: [],
            level: this.form.level,
            notificationModeCodes: this.form.serious,
            equipmentRun: {//设备类型
              equipmentTrigger: {
                equipments: [],
              },
            },
            equipmentOffLine: {//设备离线
              equipmentTrigger: {
                equipments: [],
              },
            },
            entWeChatRobotId: this.form.robotId?this.form.robotId:null,
          }
          if(this.staffList.length==0) {
            if(this.form.alarmGroup!='equipment'&&this.form.alarmGroup!='equipmentOffline') {
              return this.$message.error('请选择通知人')
            }
          }

          if(this.form.serious.find(item=>item=='entWeChatRobot')=='entWeChatRobot') {
            if(!this.form.robotId) {
              return this.$message.error('请选择机器人')
            }
          }
          if(this.form.alarmGroup=='equipment') {
            let equipmentName = this.equipments.some(v=>!v.equipmentName)
            if(equipmentName) return this.$message.error('请选择设备')

            for(let i=0;i<this.equipments.length;i++) {
              let data = this.equipments[i].triggerCondition
              if(!data.dataType) {
                return this.$message.error('请选择数据类型')
              }
              if(data.dataType=='Bool') {
                let tag = this.equipments.some(v=>v.triggerCondition.dataType=='Bool'&&!v.triggerCondition.boolData.boolTag)
                if(tag) return this.$message.error('请选择点表')
                if(!data.boolData.formulaType) {
                  return this.$message.error('请选择Bool运算类型')
                }
                if(!data.boolData.boolTag) return this.$message.error('请选择Bool类型Tag')
                if(!data.boolData.boolTag1) return this.$message.error('请选择Bool类型Tag')
                if(data.boolData.formulaType=='&'||data.boolData.formulaType=='||') {
                  // Tag循环
                  for(var j=0;j<data.boolData.tagCustom.length;j++) {
                    if(!data.boolData.tagCustom[j].name) return this.$message.error('请选择Bool类型Tag')
                  }
                }else {
                  data.boolData.tagCustom = [{
                    tagDrawer: false,
                    tagRadio: '',
                    name: ''
                  },
                  {
                    tagDrawer: false,
                    tagRadio: '',
                    name: ''
                  }]
                }
                if(!data.boolData.tagValue) return this.$message.error('请输入Bool类型点表值')
                if(!data.boolData.tagValueDescribe) return this.$message.error('请输入Bool类型点表值含义')
              }
              if(data.dataType=='Real') {
                let tag = this.equipments.some(v=>v.triggerCondition.dataType=='Real'&&!v.triggerCondition.realData.tag)
                if(tag) return this.$message.error('请选择点表')
                if(!data.realData.formulaType) {
                  return this.$message.error('请选择Real运算类型')
                }
                if(data.realData.formulaType=='gt'||data.realData.formulaType=='ge'||data.realData.formulaType=='lt'||data.realData.formulaType=='le') {
                  if(!data.realData.threshold) {
                    return this.$message.error('请输入标准阈值')
                  }
                  data.realData.minThreshold = null
                  data.realData.maxThreshold = null
                }
                if(data.realData.formulaType=='mid'||data.realData.formulaType=='side') {
                  if(!data.realData.minThreshold) {
                    return this.$message.error('请输入最小阈值')
                  }
                  if(!data.realData.maxThreshold) {
                    return this.$message.error('请输入最大阈值')
                  }
                  data.realData.threshold = null
                }
              }
            }
            this.equipments.forEach(v=>{
              let bo = v.triggerCondition.boolData
              let re = v.triggerCondition.realData
              let tagStr = ''
              let tagCustomArr = []
              if(bo.tagCustom.length>0) {
                bo.tagCustom.forEach(item=>{
                  tagCustomArr.push(item.name)
                })
              }
              if(v.triggerCondition.dataType=='Bool') {
                if(bo.formulaType=='empty'||bo.formulaType=='!') {
                  tagStr = bo.boolTag
                }
                if(bo.formulaType=='&') {
                  tagStr = bo.boolTag1+'&'+tagCustomArr.join('&')
                }
                if(bo.formulaType=='||') {
                  tagStr = bo.boolTag1+'||'+tagCustomArr.join('||')
                }
              }
              let boolData = {
                tag: tagStr,
                formulaType: bo.formulaType,
                unit: bo.boolUnit,
                tagDescribe: bo.boolDesc,
                tagValue: bo.tagValue,
                tagValueDescribe:bo.tagValueDescribe,
              }
              let realData = {
                tag: re.tag,
                formulaType: re.formulaType,
                threshold: re.threshold,
                minThreshold: re.minThreshold,
                maxThreshold: re.maxThreshold
              }
              let triggerCondition = {
                dataType: v.triggerCondition.dataType,
                boolData: v.triggerCondition.dataType=='Bool' ? boolData : null,
                realData: v.triggerCondition.dataType=='Real' ? realData : null,
              }
              form.equipmentRun.equipmentTrigger.equipments.push({
                gatewayEid: v.gatewayEid,
                eid: v.eid,
                equipmentBrandCode: v.equipmentBrandCode,
                equipmentName: v.equipmentName,
                triggerCondition: triggerCondition
              })
            })
            form.equipmentOffLine = null
          }else if(this.form.alarmGroup=='equipmentOffline') {
            let equipmentsOffline = this.equipmentsOffline.some(v=>!v.equipmentName)
            if(equipmentsOffline) return this.$message.error('请选择设备')
            this.equipmentsOffline.forEach(v=>{
              form.equipmentOffLine.equipmentTrigger.equipments.push({
                gatewayEid: v.gatewayEid,
                eid: v.eid,
                equipmentBrandCode: v.equipmentBrandCode,
                equipmentName: v.equipmentName
              })
            })
            form.equipmentRun = null
          }else {
            form.equipmentRun = null
            form.equipmentOffLine = null
            this.staffList.forEach(v=>{
              form.receiverIds.push(v.id)
            })
          }

          // console.log(form)
          this.loading = true;
          this.$ajax.post("alarmRulesCreate", form).then((res) => {
            this.loading = false;
            this.$message.success("成功");
            this.$router.push("/alarmRules");
          }).catch(err=>{
            this.loading = false;
          })
        }
      });
    },
  },
  mounted() {
    this.loadTreeData()
  },
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading {
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.form-box {
  max-width: 100%;
  margin: 0 auto;
  padding: 0px 0px 50px;
}
.el-input {
  width: 500px;
}
.el-textarea {
  width: 500px;
}
.el-radio {
  margin-right: 60px;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 110px)';
  margin-top: 20px;
  .tree {
    width: 220px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.staffBox {
  cursor: pointer;
  width: 490px;
  background: #fff;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  padding: 0 4px;
  border: 1px solid #DCDFE6;
}
.notify-method {
  width: 650px;
  display: flex;
}
</style>